export const appEnv = process.env.NEXT_PUBLIC_APP_ENV as string

export const talentApiUrl = process.env.NEXT_PUBLIC_TALENT_API_ROOT as string
export const talentApiUrlForSsr = process.env.NEXT_PUBLIC_TALENT_API_ROOT_FOR_SSR as string
export const publicApiUrl = process.env.NEXT_PUBLIC_PUBLIC_API_ROOT as string
export const publicApiUrlForSsr = process.env.NEXT_PUBLIC_PUBLIC_API_ROOT_FOR_SSR as string

export const appUrl = process.env.NEXT_PUBLIC_APP_URL as string
export const guideUrl = process.env.NEXT_PUBLIC_GUIDE_APP_URL as string
export const talentUrl = process.env.NEXT_PUBLIC_TALENT_APP_URL as string
export const sentryDns = process.env.NEXT_PUBLIC_SENTRY_DSN as string
export const defaultMetaTitle = 'Zキャリア'

export const addLineFriendUrl = process.env.NEXT_PUBLIC_ADD_LINE_FRIEND_URL as string

export const firebaseEmulating = !!process.env.NEXT_PUBLIC_FIREBASE_EMULATING

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
}
