import { ReactNode } from 'react'
import { Box, ChakraProvider } from '@chakra-ui/react'
import { createStandaloneToast } from '@chakra-ui/toast'
import type { AppPropsWithLayout } from 'next/app'
import Head from 'next/head'
import { PrimeReactProvider } from 'primereact/api'

import '@/utils/datadogRum'

import { defaultMetaTitle } from '@/config'
import { JotaiDevTool } from '@/modules/components/JotaiDevTool'
import { isProd, isStg } from '@/modules/utils/env'
import { useInitGTM } from '@/modules/utils/gtm/gtmEventActions'
import { theme } from '@/modules/utils/theme'

import '../styles/globals.css'

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  if (isProd || isStg) {
    // GTMの初期化
    useInitGTM()
  }

  const { ToastContainer } = createStandaloneToast()

  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  return (
    <Box>
      {!isProd && !isStg && <JotaiDevTool />}
      <Head>
        <title>{defaultMetaTitle}</title>
      </Head>
      <ChakraProvider theme={theme}>
        <PrimeReactProvider>
          <ToastContainer />
          {getLayout(<Component {...pageProps} />)}
        </PrimeReactProvider>
      </ChakraProvider>
    </Box>
  )
}

MyApp.getInitialProps = async () => ({ pageProps: {} })

export default MyApp
